<template>
  <div class="collectorDetail_wrap">
    <div class="collectorDetail_box">
      <el-card class="box_card left_box_card">
        <div class="box_card_title_wrap">
          <div class="box_card_title">采集器基本信息</div>
          <el-button
            class="Edit_btn"
            type="primary"
            size="small"
            plain
            @click="openEditCollectorDialog"
            >编辑</el-button
          >
        </div>
        <div class="detail_main_wrap">
          <div class="left_img_box">
            <img :src="`${baseURL}${equipmentPic}`" alt="设备图片" />
          </div>
          <div class="right_text_info_box">
            <div class="detail_info_row">
              <span>设备名称：</span
              >{{ collectDetailData.equipmentAndChipName?.split('/')[0] }}
            </div>
            <div class="detail_info_row">
              <span>采集器名称：</span>{{ collectDetailData.chipName }}
            </div>
            <div class="detail_info_row">
              <span>采集器ID：</span>{{ collectDetailData.code }}
            </div>
            <div class="detail_info_row">
              <span>采集器状态：</span>{{ collectDetailData.statusStr }}
            </div>
            <div class="detail_info_row">
              <span>接入时间：</span>{{ collectDetailData.gmtCreate }}
            </div>
            <div class="waringNotifierList_wrap">
              <div class="waringNotifierList_title">
                <div>告警通知人：</div>
                <el-switch
                  v-model="collectDetailData.openNotify"
                  inline-prompt
                  active-text="开启"
                  inactive-text="关闭"
                  :style="`
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  `"
                  @change="handleSwitchNotify"
                />
              </div>
              <div class="waringNotifierList_main">
                <div
                  class="waringNotifier_item"
                  v-for="waringNotifier in collectDetailData.waringNotifierList"
                  :key="waringNotifier.phone"
                >
                  <div class="waringNotifier_item_name">
                    {{ waringNotifier.name }}
                  </div>
                  <div class="waringNotifier_item_contactWay">
                    {{ waringNotifierOptions[waringNotifier.contactWay] }}
                  </div>
                  <div class="waringNotifier_item_phone">
                    {{ waringNotifier.phone }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box_card right_box_card">
        <Weather :cityCode="cityCode" />
      </el-card>
    </div>
    <!-- 采集器单元列表 -->
    <div class="collectorUnitList_box">
      <div class="collectorUnitList_wrap">
        <div class="collectorUnitList_head">
          <div class="collectorUnitList_head_left">采集单元列表</div>
          <div class="collectorUnitList_head_right">
            <el-radio-group
              class="collectorUnitList_status_select"
              @change="searchCollectorUnitList"
              v-model="collectorUnitListSearchData.statusStr"
            >
              <el-radio-button label="全部"></el-radio-button>
              <el-radio-button label="正常"></el-radio-button>
              <el-radio-button label="报警"></el-radio-button>
              <el-radio-button label="离线"></el-radio-button>
              <el-radio-button label="预警"></el-radio-button>
            </el-radio-group>
            <el-input
              class="collectorUnitList_name_input"
              suffix-icon="el-icon-search"
              placeholder="请输入关键字"
              v-model="collectorUnitListSearchData.name"
              @input="searchCollectorUnitList"
              clearable
            >
            </el-input>
          </div>
        </div>
        <div class="collectorUnitList_wrap_main">
          <el-table
            :data="collectorUnitList"
            stripe
            :row-style="{ height: '75px' }"
            style="width: 100%"
            :show-header="false"
          >
            <el-table-column min-width="25%">
              <template #default="{ row }">
                <div class="scope">
                  <div class="collectorUnit_name">{{ row.name }}</div>
                  <div>ID：{{ row.code }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="20%">
              <template #default="{ row }">
                <div class="time">接入时间</div>
                <div class="time">{{ row.gmtCreate }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="15%">
              <template #default="{ row }">
                <div class="scope">
                  <div>{{ row.metrics }}</div>
                  <div>{{ row.metricValue }} {{ row.metricValueUnit }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="15%">
              <template #default="{ row }">
                <div class="scope">
                  <div>指标采集时间</div>
                  <div>{{ row.collectTime }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="10%">
              <template #default="{ row }">
                <div class="scope">
                  <div>状态</div>
                  <div>{{ statusOptions[row.status] }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="15%">
              <template #default="{ row }">
                <div class="btns">
                  <div class="btn" @click="goCollectorUnitDetailPage(row.id)">
                    查看
                  </div>
                  <div class="line"></div>
                  <div class="btn" @click="eidtCollectorUnit(row.id)">编辑</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination_row">
            <div class="pagination_row_left">
              共{{ totalCollectorUnitNum }}条
            </div>
            <div class="pagination_row_right">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalCollectorUnitNum"
                :page-size="PAGESIZE"
                v-model:current-page="collectorUnitListSearchData.pageNum"
                @current-change="searchCollectorUnitList"
                class="pagination_item"
              >
              </el-pagination>
              <div class="pagination_row_input_wrap">
                跳转到<el-input-number
                  class="pagination_row_input_box"
                  style="height: 30px"
                  v-model="pageInputNum"
                  :step="1"
                  :precision="0"
                  :min="1"
                  :max="maxPageNum"
                  size="small"
                />页
              </div>
              <el-button
                style="height: 30px"
                class="pagination_row_btn"
                size="small"
                @click="handleJumpPage"
                >确定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="editCollectorDialogVisiable"
      title="编辑采集器"
      width="50%"
      class="edit_collector_dialog_box"
    >
      <div class="dialog_main">
        <div class="dialog_main_title">编辑采集器</div>
        <el-form
          :model="editCollectorDetailData"
          label-width="180px"
          label-position="left"
        >
          <el-form-item label="采集器ID" prop="code">
            <div>{{ editCollectorDetailData.code }}</div>
          </el-form-item>
          <el-form-item label="采集器名称" prop="chipName">
            <el-input
              class="description_input"
              v-model="editCollectorDetailData.chipName"
              placeholder="请输入采集器名称"
              style="width: 50%"
            />
          </el-form-item>
          <!-- <el-form-item label="采集器状态">
            <div>{{ editCollectorDetailData.statusStr }}</div>
          </el-form-item>
          <el-form-item label="接入时间">
            <div>{{ editCollectorDetailData.gmtCreate }}</div>
          </el-form-item> -->
          <el-form-item label="是否开启通知">
            <el-switch
              v-model="editCollectorDetailData.openNotify"
              inline-prompt
              active-text="开启"
              inactive-text="关闭"
              :style="`
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  `"
            />
          </el-form-item>
          <el-form-item label="报警通知人名称列表">
            <div>{{ editCollectorDetailData.notifierNameStr }}</div>
          </el-form-item>
        </el-form>
        <div class="dialog_main_title">
          <div>告警通知人:</div>
          <el-button type="primary" @click="handleAddWaringNotifier"
            >添加</el-button
          >
        </div>
        <div
          class="waring_notifier_row"
          v-for="waringNotifier in editCollectorDetailData.waringNotifierList"
          :key="waringNotifier.id"
        >
          <div class="waring_notifier_left_wrap">
            <el-input
              class="waring_notifier_name_input"
              v-model="waringNotifier.name"
            ></el-input>
            <el-select
              class="waring_notifier_contactWay_select"
              v-model="waringNotifier.contactWay"
            >
              <el-option
                v-for="item in contactWayOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-input
              class="waring_notifier_phone_input"
              v-model="waringNotifier.phone"
            ></el-input>
          </div>

          <el-button
            type="danger"
            @click="handleDeleteWaringNotifier(waringNotifier.id)"
            >删除</el-button
          >
        </div>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <!-- <el-button @click="handleClose">取消</el-button> -->
          <el-button @click="handleCancleEditCollector" size="default"
            >取消</el-button
          >
          <el-button type="primary" @click="handleEditCollector" size="default"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="editCollectorUnitDialogVisiable"
      title="设置告警阈值"
      width="50%"
      class="edit_collectorUnit_dialog_box"
    >
      <div class="dialog_main">
        <el-form
          :model="editCollectorUnitDetailData"
          label-width="180px"
          label-position="left"
        >
          <div class="dialog_row">
            <div>采集器单元名称：</div>
            <div class="dialog_row_text">
              {{ editCollectorUnitDetailData.name }}
            </div>
          </div>
          <div class="dialog_row_box">
            <div class="dialog_box_row">
              <div>
                <el-switch
                  v-model="earlyWarningItem.flag"
                  style="--el-switch-on-color: #13ce66"
                />
              </div>
              <div class="dialog_row_text" style="color: #f59a23">
                预警项设置：
              </div>
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最大值:</div>
              <el-input-number
                v-model="earlyWarningItem.maxThreshold"
                :precision="2"
                :step="0.1"
                size="small"
              />
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最小值:</div>
              <el-input-number
                v-model="earlyWarningItem.minThreshold"
                :precision="2"
                :step="0.1"
                size="small"
              />
            </div>
          </div>
          <div class="dialog_row_box">
            <div class="dialog_row">
              <div>
                <el-switch
                  v-model="warningItem.flag"
                  style="--el-switch-on-color: #13ce66"
                />
              </div>
              <div class="dialog_row_text" style="color: #d84242">报警项设置:</div>
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最大值:</div>
              <el-input-number
                v-model="warningItem.maxThreshold"
                :precision="2"
                :step="0.1"
                size="small"
              />
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最小值:</div>
              <el-input-number
                v-model="warningItem.minThreshold"
                :precision="2"
                :step="0.1"
                size="small"
              />
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <!-- <el-button @click="handleClose">取消</el-button> -->
          <el-button @click="handleCancleEditCollectorUnit" size="default"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="handleEditCollectorUnit"
            size="default"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import router from '@/router/index.js';
import {
  getEquipmentDetail,
  getCollectorDetail,
  putCollector,
  getCollectorUnitList,
  getCollectorUnitDetail,
  putThreshold
} from '@/api/equipment.js';
import { reactive, ref, watch, onMounted } from 'vue';
import { getItem, setItem } from '@/utils/storage';
import { Weather } from './components';

// const baseURL = 'https://iottest.blueasiainfo.com:29202';
const baseURL = process.env.VUE_APP_BASE_API;

let initFlag = true;

// 监听;
watch(
  () => router.currentRoute.value.fullPath,
  (newValue, oldValue) => {
    console.log('watch', newValue);
    const chipId = getItem('chipId');
    const cityCode = getItem('cityCode');
    const equipmentId = getItem('equipmentId');

    if (newValue == '/equipment/collectorDetail') {
      if (chipId != null) {
        router.push({
          path: '/equipment/collectorDetail',
          query: { id: chipId, cityCode }
        });
        initFlag = false;
      } else if (equipmentId) {
        // debugger;
        router.push({
          path: '/equipment/equipmentDetail',
          params: { id: equipmentId }
        });
        initFlag = false;
        ElMessage.error('请从设备详情中选择采集器详情');
      } else {
        // debugger;
        router.push({
          path: '/equipment/equipmentList'
        });
        initFlag = false;
        ElMessage.error('请从设备列表中选择设备详情');
      }
    }
  },
  { immediate: true }
);
const chipId = getItem('chipId');
const cityCode = getItem('cityCode');

// 获取采集器详情
const waringNotifierOptions = {
  1: '短信通知',
  2: '电话+短信通知',
  3: '短信通知'
};
const collectDetailData = reactive({
  chipId: 0,
  chipName: '',
  chipStatus: 0,
  code: '',
  equipmentAndChipName: '',
  equipmentId: 0,
  gmtCreate: '',
  member: true,
  metrics: [],
  notifierNameStr: '',
  openNotify: true,
  statusStr: '',
  waringMsgList: [],
  waringNotifierList: []
});
function getCollectorDetailData(id) {
  getCollectorDetail(id).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      // 初始化要展示的 采集器详情
      for (const key of Object.keys(collectDetailData)) {
        collectDetailData[key] = data[key];
      }
      // 获取采集单元列表信息
      searchCollectorUnitList();
      // 获取设备图片
      getEquipmentPic(data.equipmentId);
    } else {
      // debugger;
      console.error('getCollectorDetailData');
      ElMessage.error(msg);
    }
  });
}

// 打开 关闭 告警通知
function handleSwitchNotify(val) {
  collectDetailData.openNotify = val;
  const { chipId, chipName, code, equipmentId, waringNotifierList } =
    collectDetailData;
  const params = {
    chipId,
    chipName,
    code,
    equipmentId,
    openNotify: val,
    waringNotifierList
  };
  putCollector(params).then((res) => {
    const { code, msg } = res;
    if (code === 200) {
      ElMessage.success('修改成功!');
    } else {
      console.error('handleSwitchNotify');
      ElMessage.error(msg);
    }
  });
}

// 编辑采集器
const editCollectorDialogVisiable = ref(false);
let currentWaringNotifierId = 0;
const editCollectorDetailData = reactive({
  chipId: 0, // 当前编辑的采集器chipId
  chipName: '', // 采集器名称
  chipStatus: 0, // 状态，1:正常， 2:报警，3:离线, 4:预警
  code: '', // 采集器串号
  equipmentAndChipName: '', // 隶属设备/采集器
  equipmentId: 0, // 设备id
  gmtCreate: '', // 接入时间
  member: true, // 是否拥有会员套餐
  notifierNameStr: '', // 报警通知人名称列表
  openNotify: true, // 是否开启通知
  statusStr: '', // 采集器状态字符串
  waringNotifierList: [
    // 报警通知人列表
    // {
    //   contactWay: 0, // 通知方式 电话方式通知:1, 短信方式通知:2, 电话+短信通知: 3.电话
    //   name: '', // 报警通知人
    //   phone: '' // 手机号
    // }
  ]
});

const contactWayOptions = [
  { value: 1, label: '短信通知' },
  { value: 2, label: '电话+短信通知' },
  { value: 3, label: '电话' }
];

// 添加通知人
function handleAddWaringNotifier() {
  editCollectorDetailData.waringNotifierList.push({
    contactWay: 1,
    name: '',
    phone: '',
    id: currentWaringNotifierId++
  });
}

// 删除通知人
function handleDeleteWaringNotifier(waringNotifier) {
  const index = editCollectorDetailData.waringNotifierList.findIndex(
    (item) => item.id === waringNotifier.id
  );
  editCollectorDetailData.waringNotifierList.splice(index, 1);
}

// 取消编辑采集器
function handleCancleEditCollector() {
  editCollectorDialogVisiable.value = false;
}

// 编辑采集器
function handleEditCollector() {
  const {
    chipId,
    chipName,
    // chipStatus,
    code,
    equipmentId,
    openNotify,
    waringNotifierList
  } = editCollectorDetailData;
  const params = {
    chipId,
    chipName,
    // chipStatus,
    code,
    equipmentId,
    openNotify,
    waringNotifierList
  };
  // TODO: 对输入数据的校验
  // waringNotifierList
  console.log('handleEditCollector=>', params);
  putCollector(params).then((res) => {
    console.log(res);
    const { code, msg } = res;
    if (code === 200) {
      console.log(res);
      ElMessage.success('修改成功!');
      editCollectorDialogVisiable.value = false;
      getCollectorDetailData(chipId);
    } else {
      ElMessage.error(msg);
      console.log('修改失败!', res);
    }
  });
}

// 获取设备照片
const equipmentPic = ref('');
function getEquipmentPic(id) {
  getEquipmentDetail(id).then((res) => {
    const { data, code, msg } = res;
    if (code === 200) {
      equipmentPic.value = data.pic;
    } else {
      ElMessage.error(msg);
      console.error('equipmentPic');
    }
  });
}
// 获取天气信息
// 天气数据
// const weatherData = reactive({
//   aqi: {},
//   // {
//   //   airLevel: '', // 空气质量
//   //   kaichuang: '', // 适宜开窗
//   //   pm25: '', // PM2.5
//   //   pm25Desc: '', // PM2.5描述
//   //   waichu: '' // 适宜外出
//   // },
//   city: '', // 城市中文名称
//   date: '', // 日期 当前时间
//   hours: [
//     // 24小时天气预报
//     // {
//     //   aqi: '',
//     //   aqiNum: '',
//     //   hours: '',
//     //   tem: '',
//     //   wea: '',
//     //   weaImg: '',
//     //   win: '',
//     //   winSpeed: ''
//     // }
//   ],
//   week: '', // 周几 当前星期
//   tem: '', // 实时温度 例子: 30
//   win: '', // 风向 例子: 西南风
//   winSpeed: '', // 风力等级 例子: 1级
//   weaImg: '', // 天气情况图标 例子: '/profile/logo/cucumber/yun.png'
//   wea: '', // 天气 例子: '多云'
//   tem2: '', // 最低温度 例子: '28'
//   tem1: '', // 最高温度 例子: '36'
//   sunrise: '', // 日出时间 例子: '03:50'
//   sunset: '', // 日落时间 例子: '19:23'
//   humidity: '', // 湿度 例子: '75%'
//   pressure: '' // 气压 例子: '994'
// });

// // 获取天气数据
// function getWeather(cityCode) {
//   const areaCode = Number(
//     Array.from(cityCode + '')
//       .splice(0, 4)
//       .join('') + '00'
//   );
//   const params = {
//     areaCode,
//     cityCode
//   };
//   getAreaWeather(params).then((res) => {
//     const { data, code, msg } = res;
//     if (code === 200) {
//       for (const key of Object.keys(weatherData)) {
//         weatherData[key] = data[key];
//       }
//     } else {
//       ElMessage.error(msg);
//       console.log('getWeather 出错了', res);
//     }
//   });
// }

// 编辑采集器
function openEditCollectorDialog() {
  editCollectorDialogVisiable.value = true;
  editCollectorDetailData.chipId = chipId;
  // TODO:打开编辑弹窗
  getCollectorDetail(chipId).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      for (const key of Object.keys(editCollectorDetailData)) {
        editCollectorDetailData[key] = data[key];
      }
      // 初始化当前id
      currentWaringNotifierId = 0;
      // 给 waringNotifierList 配id
      editCollectorDetailData.waringNotifierList.forEach((item) => {
        item.id = currentWaringNotifierId++;
      });

      // console.log('eidtCollector data', data);
      // console.log(
      //   'eidtCollector editCollectorDetailData',
      //   editCollectorDetailData
      // );
    } else {
      ElMessage.error(msg);
      console.error('openEditCollectorDialog');
    }
  });
}

// 采集器列表(用 el-table展示)
const collectorUnitList = reactive([]);

// 搜索要用到的数据
const collectorUnitListSearchData = reactive({
  name: '',
  statusStr: '全部',
  pageNum: 1 // 当前页码
});

// 按照条件搜索对应的采集器
function searchCollectorUnitListApi(params) {
  getCollectorUnitList(params).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      const { list, total, pages } = data;
      const length = collectorUnitList.length;
      collectorUnitList.splice(0, length, ...list);
      totalCollectorUnitNum.value = total;
      maxPageNum.value = pages;
    } else {
      ElMessage.error(msg);
      console.error('searchCollectorUnitListApi');
      console.error(res);
    }
  });
}

// 按照条件搜索对应的采集器
function searchCollectorUnitList() {
  // 按照条件搜索对应的采集器
  const { name, statusStr, pageNum } = collectorUnitListSearchData;
  const { code } = collectDetailData;
  const chipId = getItem('chipId');
  const params = {
    name,
    pageNum,
    pageSize: PAGESIZE,
    collectorId: chipId,
    collectorCode: code
  };
  let status;
  switch (statusStr) {
    case '正常':
      status = 1;
      break;
    case '报警':
      status = 2;
      break;
    case '离线':
      status = 3;
      break;
    case '预警':
      status = 4;
      break;
  }
  if (status) {
    params.status = status;
  }
  console.log('searchCollectorUnitList params=>', params);
  searchCollectorUnitListApi(params);
}

// #region 分页逻辑部分
const totalCollectorUnitNum = ref(1); // 总数据条数
const PAGESIZE = 10; // 一页展示的数据条数

const pageInputNum = ref(1); // 输入框页码
const maxPageNum = ref(1); // 输入框中最大页码

// 页码跳转
function handleJumpPage() {
  collectorUnitListSearchData.pageNum = pageInputNum.value;
  searchCollectorUnitList();
}
// #endregion
// 采集器状态数组
const statusOptions = {
  1: '正常',
  2: '报警',
  3: '离线',
  4: '预警'
};

// 跳转到采集器单元详情页面
function goCollectorUnitDetailPage(id) {
  console.log('goCollectorUnitDetailPage id', id);
  setItem('UnitId', id);
  router.push({
    path: '/equipment/collectorUnitDetail',
    query: { id }
  });
}

function eidtCollectorUnit(id) {
  //   console.log('item', item.id);
  // TODO:
  console.log('eidtCollectorUnit');
  editCollectorUnitDialogVisiable.value = true;
  getCollectorUnitDetailApi(id);
}

// 编辑采集器单元弹框是否显示
const editCollectorUnitDialogVisiable = ref(false);
// 编辑 采集器单元详情所需的数据
const editCollectorUnitDetailData = reactive({
  code: '',
  collectTime: '',
  collectTimeList: {},
  collector: '',
  collectorCode: '',
  collectorId: 0,
  dataField: [
    // {
    //   dataMarker: 'string',
    //   dataMarkerName: 'string'
    // }
  ],
  gmtCreate: '',
  gmtUpdate: '',
  id: 0,
  metricValue: '',
  metricValueUnit: '',
  metrics: '',
  metricsType: 0,
  multiData: 0,
  multiMetricValue: {
    // additionalProp1: '',
    // additionalProp2: '',
    // additionalProp3: ''
  },
  multiThreshold: {
    // additionalProp1: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ],
    // additionalProp2: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ],
    // additionalProp3: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ]
  },
  name: '',
  status: '',
  // 报警阈值
  threshold: [
    // {
    //   level: 0, // 报警级别，4-预警, 2-报警
    //   maxThreshold: 0, // 最大阈值
    //   metricType: '', // 指标类型
    //   minThreshold: 0 // 最小阈值
    // }
    // {
    //     "level": 4,
    //     "metricType": "undefined",
    //     "minThreshold": 0.98,
    //     "maxThreshold": 1.1
    //   },
    //   {
    //     "level": 2,
    //     "metricType": "undefined",
    //     "minThreshold": 0.9,
    //     "maxThreshold": 1.2
    //   }
  ],
  waringMsgList: [
    // {
    //   collectorUnitCode: '',
    //   collectorUnitPath: '',
    //   content: {
    //     additionalProp1: {},
    //     additionalProp2: {},
    //     additionalProp3: {}
    //   },
    //   gmtCreate: '',
    //   id: 0,
    //   level: 0,
    //   status: true
    // }
  ]
});
// 预警项
const earlyWarningItem = reactive({
  flag: false,
  minThreshold: 0,
  maxThreshold: 0
});
// 报警项
const warningItem = reactive({
  flag: false,
  minThreshold: 0,
  maxThreshold: 0
});
function clearWarningStatus() {
  earlyWarningItem.flag = false;
  earlyWarningItem.minThreshold = 0;
  earlyWarningItem.maxThreshold = 0;
  warningItem.flag = false;
  warningItem.minThreshold = 0;
  warningItem.maxThreshold = 0;
}
// 当前编辑的 采集器单元ID
let currentEditCollectUnitId = 0;

// 获取采集器单元详情
function getCollectorUnitDetailApi(id) {
  currentEditCollectUnitId = id;
  getCollectorUnitDetail(id).then((res) => {
    const { code, msg, data } = res;
    if (code === 200) {
      for (const key of Object.keys(editCollectorUnitDetailData)) {
        editCollectorUnitDetailData[key] = data[key];
      }
      // 处理 报警阈值
      const warningItemIndex = editCollectorUnitDetailData.threshold.findIndex(
        (item) => item.level === 2
      );
      if (warningItemIndex !== -1) {
        warningItem.flag = true;
        warningItem.minThreshold =
          editCollectorUnitDetailData.threshold[warningItemIndex].minThreshold;
        warningItem.maxThreshold =
          editCollectorUnitDetailData.threshold[warningItemIndex].maxThreshold;
      }
      // 处理 预警阈值
      const earlyWarningItemIndex =
        editCollectorUnitDetailData.threshold.findIndex(
          (item) => item.level === 4
        );
      if (earlyWarningItemIndex !== -1) {
        earlyWarningItem.flag = true;
        earlyWarningItem.minThreshold =
          editCollectorUnitDetailData.threshold[
            earlyWarningItemIndex
          ].minThreshold;
        earlyWarningItem.maxThreshold =
          editCollectorUnitDetailData.threshold[
            earlyWarningItemIndex
          ].maxThreshold;
      }
      console.log(editCollectorUnitDetailData.threshold);
      console.log(earlyWarningItem);
      console.log(warningItem);
    } else {
      ElMessage.error(msg);
    }
  });
}

// 取消编辑采集器单元
function handleCancleEditCollectorUnit() {
  editCollectorUnitDialogVisiable.value = false;
}
// 编辑采集器单元
function handleEditCollectorUnit() {
  console.log('handleEditCollectorUnit');
  const thresholdList = [];
  if (earlyWarningItem.flag) {
    thresholdList.push({
      level: 4,
      maxThreshold: earlyWarningItem.maxThreshold,
      minThreshold: earlyWarningItem.minThreshold
    });
  }
  if (warningItem.flag) {
    thresholdList.push({
      level: 2,
      maxThreshold: warningItem.maxThreshold,
      minThreshold: warningItem.minThreshold
    });
  }
  const data = {
    collectorUnitId: currentEditCollectUnitId,
    thresholdList
  };
  putThreshold(data).then((res) => {
    const { code, msg } = res;
    if (code === 200) {
      // 刷新一下,因为可能会有预警或者报警
      getCollectorDetailData(chipId);
      editCollectorUnitDialogVisiable.value = false;
      clearWarningStatus();
    } else {
      ElMessage.error(msg);
    }
  });
}

function init() {
  getCollectorDetailData(chipId);
  // searchCollectorUnitList();
}
onMounted(() => {
  initFlag && init();
});
</script>

<style lang="scss" scoped>
.collectorDetail_wrap {
  background-color: #fafafa;
  .collectorDetail_box {
    background-color: #fff;
    margin: 1rem 14px 8px 14px;
    display: flex;
    justify-content: space-between;

    .box_card {
      width: 49.5%;
      min-width: 710px;

      // 采集器详情
      &.left_box_card {
        .box_card_title_wrap {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .box_card_title {
            font-size: 18px;
          }
        }
        .detail_main_wrap {
          display: flex;
          justify-content: space-between;
          .left_img_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45%;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
          .right_text_info_box {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .detail_info_row {
              // 省略三连
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              font-size: 14px;
              width: 100%;
              height: 30px;
              line-height: 30px;
              // color: rgba(0, 0, 0, 0.65);
              color: black;
              padding-left: 5%;
              span {
                color: #9a9a9a;
              }
            }
            .waringNotifierList_wrap {
              width: 100%;
              display: flex;
              font-size: 14px;
              padding-left: 5%;
              .waringNotifierList_title {
                height: 30px;
                line-height: 30px;
                color: #9a9a9a;
                flex: none;
              }
              .waringNotifierList_main {
                width: 78%;
                .waringNotifier_item {
                  display: flex;
                  width: 100%;
                  flex: none;
                  justify-content: space-between;
                  height: 30px;
                  align-items: center;
                  .waringNotifier_item_name {
                    flex: none;
                  }
                  .waringNotifier_item_contactWay {
                    flex: none;
                  }
                  .waringNotifier_item_phone {
                    flex: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .collectorUnitList_box {
    margin: 14px;
    background-color: #fff;

    .collectorUnitList_wrap {
      padding: 14px;

      .collectorUnitList_head {
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .collectorUnitList_head_left {
          font-size: 18px;
        }

        .collectorUnitList_head_right {
          display: flex;
          height: 40px;
          align-items: center;

          .collectorUnitList_status_select {
            flex: 2;
            margin-right: 15px;
          }

          .collectorUnitList_name_input {
            flex: 1.2;
            height: 32px;
            :deep(.el-input__inner) {
              height: 32px;
            }
          }
        }
      }

      .collectorUnitList_wrap_main {
        margin-top: 10px;
        :deep(.scope) {
          display: flex;
          flex-direction: column;
          .collectorUnit_name {
            color: rgba(0, 0, 0, 0.95);
            font-size: 14px;
          }
        }

        :deep(.btns) {
          display: flex;

          .btn {
            margin: 0 8px;
            color: #1890ff;
            cursor: pointer;
          }

          .line {
            margin-top: 2px;
            width: 1px;
            height: 16px;
            border-left: 1px solid #dcdfe6;
          }
        }

        .pagination_row {
          margin-top: 10px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .pagination_row_left {
            font-size: 13px;
            color: #606266;
          }

          .pagination_row_right {
            display: flex;
            font-size: 13px;
            align-items: center;

            .pagination_item {
              height: 30px;
              :deep(button) {
                height: 30px;
              }
              :deep(ul li) {
                height: 30px;
              }
            }
            .pagination_row_input_wrap {
              margin-left: 10px;
              .pagination_row_input_box {
                margin-left: 10px;
                margin-right: 10px;
              }
              // 这部分是可以自然设置number input部分样式(保留)
              // #region
              // .pageInput {
              //   &:deep(.el-input__inner) {
              //     height: 30px;
              //     line-height: 30px;
              //     box-sizing: border-box;
              //   }
              //   &:deep(.el-input-number__decrease) {
              //     height: 30px;
              //     line-height: 30px;
              //     box-sizing: border-box;
              //   }
              //   &:deep(.el-input-number__increase) {
              //     height: 30px;
              //     line-height: 30px;
              //     box-sizing: border-box;
              //   }
              // }
              // #endregion
            }

            .pagination_row_btn {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .edit_collectorUnit_dialog_box {
    .dialog_main {
      .dialog_row {
        display: flex;
        align-items: center;
        height: 30px;
        .dialog_row_text {
          margin-left: 15px;
        }
      }
      .dialog_row_box {
        margin-top: 10px;
        .dialog_box_row {
          display: flex;
          align-items: center;
          height: 30px;
          .dialog_row_text {
            margin-left: 15px;
          }
        }

        .warning_row {
          display: flex;
          align-items: center;
          height: 30px;
          .warning_row_text {
            margin-right: 15px;
          }
        }
      }
    }
  }

  // 编辑采集器
  .edit_collector_dialog_box {
    .dialog_main {
      .dialog_main_title {
        display: flex;
        text-align: left;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .waring_notifier_row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .waring_notifier_name_input {
          width: 150px;
          :deep(.el-input__wrapper) {
            width: 150px;
          }
          margin-right: 15px;
        }
        .waring_notifier_contactWay_select {
          :deep(.el-input) {
            width: 150px;
          }
          margin-right: 15px;
        }
        .waring_notifier_phone_input {
          width: 200px;
          :deep(.el-input__wrapper) {
            width: 200px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.collectorDetail_wrap {
  .el-dialog {
    .el-dialog__header {
      height: 50px;
      .el-dialog__headerbtn {
        .el-icon.el-dialog__close {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
